import React, { useContext, useState } from 'react';

import { Button, Group, ImageWrapper, GeneralText, Title } from 'atoms';

import Wrapper from './components/Wrapper';

import { useDraftContest } from 'organisms/ContestBuilder';
import { navigate } from '@reach/router';

import { MainContext } from 'containers/MainContainer';

import footballBackground from 'assets/media/wallpapers/football-button.png';
import basketballBackground from 'assets/media/wallpapers/basketball-button.png';

import NFLLogo from 'assets/media/logos/NFL.svg';
import NBALogo from 'assets/media/logos/NBA.svg';

import colors from 'assets/themes/colors';

export const Home = () => {
  const { resetGame } = useContext(MainContext);

  const { create, draft } = useDraftContest();

  const [loadedImages, pushLoadedImages] = useState(0);

  return (
    <Wrapper isReady={loadedImages === 2}>
      <Group
        isVertical
        isCentered
        padding={'50px 20px'}
        spaceSize={5}
        style={{
          backgroundColor: colors.primary,
        }}>
        <Title isMedium color={colors.accent}>
          Select a sport
        </Title>
        <GeneralText isSmall color={colors.white}>
          Pick a league to start playing our contests!
        </GeneralText>
      </Group>

      <Button
        onClick={() => {
          if (!draft.predictions) {
            create('nfl').then(() => navigate('/gametype'));
            return;
          }
          resetGame().then(() => {
            create('nfl').then(() => navigate('/gametype'));
          });
        }}
        isBare>
        <div style={{ position: 'relative', display: 'flex', height: 192.25 }}>
          <Title
            isLarge
            color={colors.white}
            style={{ position: 'absolute', top: 30, left: 30 }}>
            FOOTBALL
          </Title>
          <ImageWrapper
            source={NFLLogo}
            height={48}
            style={{ position: 'absolute', bottom: 30, right: 30 }}
          />
          <ImageWrapper
            onLoad={() => pushLoadedImages(loadedImages + 1)}
            source={footballBackground}
          />
        </div>
      </Button>

      <Button
        onClick={() => {
          if (!draft.predictions) {
            create('nba').then(() => navigate('/gametype'));
            return;
          }
          resetGame().then(() => {
            create('nba').then(() => navigate('/gametype'));
          });
        }}
        isBare>
        <div style={{ position: 'relative', display: 'flex', height: 192.25 }}>
          <Title
            isLarge
            color={colors.white}
            style={{ position: 'absolute', top: 30, left: 30, zIndex: 1 }}>
            BASKETBALL
          </Title>
          <ImageWrapper
            source={NBALogo}
            height={48}
            style={{ position: 'absolute', bottom: 30, right: 30, zIndex: 1 }}
          />
          <ImageWrapper
            onLoad={() => pushLoadedImages(loadedImages + 1)}
            source={basketballBackground}
          />
        </div>
      </Button>

      <div
        style={{
          padding: 20,
          textAlign: 'center',
          backgroundColor: colors.secondary,
        }}>
        <GeneralText color={colors.primary}>
          *More sports are coming soon. Stay tuned!
        </GeneralText>
      </div>
    </Wrapper>
  );
};

export default Home;
