import React from 'react';
import PropTypes from 'prop-types';

import CardWrapper from 'molecules/CardWrapper';
import CompetitionBlock from 'organisms/CompetitionBlock';

import List from 'atoms/List';
import Button from 'atoms/Button';
import Group, { Fill } from 'atoms/Group';
import GeneralText from 'atoms/GeneralText';
import Icon from 'atoms/Icon';

import { Bullhorn, Undo } from 'assets/icons';

import InlinePlayerCard from 'organisms/InlinePlayerCard';

import parseDateTime from 'services/parseDateTime';

import useSportDate from 'hooks/useSportDate';

import formatMetrics from 'services/formatMetrics';
import { camelCaseToSpace, sortBy } from 'services/utilities';
import colors from 'assets/themes/colors';

const CurrentTeamCard = ({
  currentTeam,
  onClickToClear,
  hasTeams,
  gameType,
  current,
  onChange,
}) => {
  const isHome = currentTeam && currentTeam.homeTeam;

  const { diff, labels, isStartDate, isUsingReadyLimitRange } = useSportDate();

  const days = diff.days > 0 ? labels.days + ' ' : '';
  const hours = diff.hours > 0 ? labels.hours + ' and ' : '';

  let countdown = <b>{`${days}${hours}${labels.minutes}`}</b>;

  let placeholder = (
    <Group
      isFullWidth
      isVertical
      isCentered
      isVerticalCentered
      spaceSize={4}
      style={{ height: 356 }}>
      <GeneralText style={{ textAlign: 'center' }}>
        New lineups will be published in
      </GeneralText>
      <GeneralText isLarge style={{ textAlign: 'center' }}>
        {countdown}.
      </GeneralText>
      <GeneralText style={{ textAlign: 'center' }}>
        Come back then to play again.
      </GeneralText>
    </Group>
  );

  if (isStartDate && !isUsingReadyLimitRange) {
    placeholder = (
      <Group
        isFullWidth
        isVertical
        isCentered
        isVerticalCentered
        spaceSize={4}
        style={{ height: 356 }}>
        <GeneralText style={{ textAlign: 'center' }}>
          We{"'"}re almost done getting lineups ready!
          <br />
          Come back any second to play again!
        </GeneralText>
      </Group>
    );
  }

  return (
    <div style={{ padding: 15, paddingTop: 0 }}>
      <CardWrapper
        topLabel={
          <>
            {hasTeams ? (
              <Group isFullWidth isVerticalCentered spaceSize={5}>
                <GeneralText isMedium color="#FFFFFF">
                  <strong>Lineup</strong>
                </GeneralText>
                <Fill />
                <Button
                  data-guided-tour="team-select-1"
                  specSelector={`teams-number-selection-1`}
                  isXSmall
                  onClick={() => onChange(0)}
                  isDisabled={current === 1}
                  isPrimary={current === 0}>
                  1
                </Button>
                <Button
                  data-guided-tour="team-select-2"
                  specSelector={`teams-number-selection-2`}
                  isXSmall
                  onClick={() => onChange(1)}
                  isDisabled={current === 0}
                  isDanger={current === 1}>
                  2
                </Button>
                <Button
                  isBare
                  onClick={() => onClickToClear()}
                  style={{
                    marginLeft: 8,
                    marginRight: -2,
                  }}>
                  <Icon size={16} color="white">
                    <Undo />
                  </Icon>
                </Button>
              </Group>
            ) : (
              <Group isFullWidth isVerticalCentered style={{ height: 29 }}>
                <GeneralText isMedium color="#FFFFFF">
                  <strong></strong>
                </GeneralText>
              </Group>
            )}
          </>
        }
        style={{ margin: 'auto', maxWidth: 415 }}>
        {!hasTeams && !currentTeam && placeholder}

        {!currentTeam && hasTeams && (
          <div
            style={{
              height: 356,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <Icon size={80} color={colors.accent}>
              <Bullhorn />
            </Icon>
            <GeneralText
              isMedium
              style={{ marginTop: 20, textAlign: 'center' }}>
              <b>
                Select a team lineup by
                <br />
                clicking on a team logo above.
              </b>
            </GeneralText>
          </div>
        )}

        {currentTeam && (
          <>
            <CompetitionBlock
              selectedTeam={{
                name: currentTeam.teamName,
                logo: isHome
                  ? currentTeam.homeTeamLogoUrl
                  : currentTeam.awayTeamLogoUrl,
              }}
              competitorTeam={{
                name: !isHome
                  ? currentTeam.homeTeamName
                  : currentTeam.awayTeamName,
                logo: !isHome
                  ? currentTeam.homeTeamLogoUrl
                  : currentTeam.awayTeamLogoUrl,
              }}
              homeTeamName={currentTeam.homeTeamName}
              startTime={parseDateTime(currentTeam.competitionStart, 'hh:mm A')}
              date={parseDateTime(currentTeam.competitionStart, 'MMM DD, YYYY')}
            />
            <div style={{ padding: '0 15px 15px' }}>
              <List>
                {sortBy(currentTeam.competitors, 'isBackupCompetitor').map(
                  (item, index) => (
                    <InlinePlayerCard
                      projections={{
                        overunder: formatMetrics(
                          item.competitor.projections.overUnder,
                          item.competitor.position,
                          String(item.competitor).toLowerCase()
                        ),
                        statpicks: formatMetrics(
                          item.competitor.projections.statPicks,
                          item.competitor.position,
                          String(item.competitor).toLowerCase()
                        ),
                      }}
                      gameType={gameType}
                      fullName={
                        item.competitor.firstName +
                        ' ' +
                        item.competitor.lastName
                      }
                      picture={item.competitor.headshotUrl}
                      stats={{
                        label: camelCaseToSpace(item.metric, true),
                        name: item.metric,
                        value: false || '-',
                      }}
                      isBackup={item.isBackupCompetitor}
                      key={index}
                    />
                  )
                )}
              </List>
            </div>
          </>
        )}
      </CardWrapper>
    </div>
  );
};

CurrentTeamCard.propTypes = {
  current: PropTypes.number,
  currentTeam: PropTypes.object,
  gameType: PropTypes.string,
  hasTeams: PropTypes.bool,
  onChange: PropTypes.func,
  onClickToClear: PropTypes.func,
};

export default CurrentTeamCard;
